<template>
	<div id="app">
		<img alt="Vue logo" src="./assets/logo.png"/>
		<br>
		<a @click='load'>Load Existing User</a>
		<br>
		<br>
		<a @click='login'>Login</a>
		<br>
		<small>sadly will throw an error without recaptcha, login on <a href='https://ezmoney.rivalry.sh' target='_blank'>ezmoney</a> as a test</small>
		<br>
		<input type='text' v-model='email' placeholder='email' />
		<br>
		<input type='password' v-model='password' placeholder='password' />
	</div>
</template>

<script>
import axios from 'axios';

axios.defaults.withCredentials = true;
const $axios = axios.create({
	baseURL: 'https://dev-app.rivalry.sh/app',
	timeout: 1000,
	// withCredentials: true,
	headers: { 'x-requested-with': 'XMLHttpRequest' }
});


export default {
	name: 'App',

	data() {
		return { email: '', password: ''}
	},

	mounted() {
		// Try to fetch xsrf on first load
		$axios.get('refresh-csrf').then(() => {
			console.log('refresh csrf success');
		})
		.catch(err => {
			console.log('x -- no luck, failed');
			console.log(err.toJSON());
		});

	},

	methods: {
		load() {
			return $axios.get('user')
				.then(response => {
					console.log('login success, response');
					console.log(response);
				})
				.catch(err => {
					console.log('x -- no luck, failed');
					console.log(err.toJSON());
				});
		},
		login() {
			return $axios.post('login', { email: this.email, password: this.password })
				.then(response => {
					console.log('login success, response');
					console.log(response);
				})
				.catch(err => {
					console.log('x -- no luck, failed');
					console.log(err.toJSON());
				});
		}
	}
}
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
}
</style>
